<template>
  <main>
    <b-card>
      <b-overlay>
        <b-row>
          <b-col v-if="isAdminGudang && myGudang" cols="12" class="mb-2">
            <h5>Lokasi Gudang: {{ myGudang.nama_gudang }}</h5>
          </b-col>
          <b-col sm="12" md="6">
            <!-- Pilih Barang -->
            <b-form-group>
              <label><strong>Pilih Barang</strong></label>
              <v-select
                :options="barangs"
                :reduce="(option) => option.value"
                v-model="form.barang_id"
                label="text"
              />
            </b-form-group>
            <!-- / -->
          </b-col>
          <b-col sm="12" md="6">
            <!-- Tujuan Gudang -->
            <b-form-group>
              <label><strong>Tujuan Mutasi</strong></label>
              <v-select :options="otherGudangs" v-model="form.tujuan" label="text" />
            </b-form-group>
            <!-- / -->
          </b-col>

          <!-- List Stok Barang -->
          <b-col cols="12" class="mt-2">
            <h5 class="mb-1">Daftar Stok Barang</h5>

            <b-table
            small
            bordered
              striped
              responsive
              :fields="fields"
              :items="availableStocks"
            >
              <template #cell(checkbox)="{ item }">
                <b-button
                  class="rounded-full"
                  variant="outline-info"
                  rounded
                  size="sm"
                  @click="addBarang(item)"
                >
                  <feather-icon icon="PlusCircleIcon"></feather-icon>
                </b-button>
              </template>

              <template #cell(barang)="{ item }">
                <strong>{{ item.barang ? item.barang.nama : "-" }}</strong>
              </template>
              <template #cell(lokasi)="{ item }">
                {{ getLokasi(item) }}
              </template>
              <template #cell(stok)="{ item }">
                {{ formatRupiah(item.stok) }}
              </template>
              <template #cell(satuan)="{ item }">
                {{ item.barang ? item.barang.satuan.satuan : "-" }}
              </template>
              <template #cell(nilai)="{ index, item }">
                <b-form-input v-if="item.isEdit" v-model="item.nilai" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
              <template #cell(id_satuan)="{ index, item }">
                <v-select v-if="item.isEdit" v-model="item.id_satuan" :options="optkonversi" label="text" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
              <template #cell(action)="{ index, item }">
                <b-form-input v-if="item.isEdit" v-model="item.jumlahEdit" />
                <b-button v-else @click.prevent="edit(index)" variant="primary" size="sm">
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </template>
            </b-table>
          </b-col>
          <!-- / -->

          <!-- List Stok yang siap dimutasi -->
          <b-col cols="12" class="mt-2 mb-3">
            <h5 class="mb-1">Daftar Mutasi</h5>
            <b-table
            small
            bordered
              striped
              responsive
              :fields="mutationFields"
              :items="mutations"
            >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(barang)="{ item }">
                <strong>{{ item.barang ? item.barang.nama : "-" }}</strong>
              </template>
              <template #cell(lokasi)="{ item }">
                {{ getLokasi(item) }}
              </template>
              <template #cell(tujuan)>
                {{ form.tujuan ? form.tujuan.text : "-" }}
              </template>
              <template #cell(satuan)="{ item }">
                <strong>{{ item.barang ? item.barang.satuan.satuan : "-" }}</strong>
              </template>
              <template #cell(satuan2)="{ item }">
                <strong>{{ item.barang ? item.barang.satuan.satuan : "-" }}</strong>
              </template>
              <template #cell(action)="{ index }">
                <b-button
                  @click.prevent="mutations.splice(index, 1)"
                  variant="danger"
                  size="sm"
                >
                  <feather-icon icon="XIcon"></feather-icon>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <section class="d-flex justify-content-end">
          <b-button
            :disabled="isIncompletedForm"
            variant="primary"
            @click.prevent="submit"
          >
            Simpan Mutasi
          </b-button>
        </section>
      </b-overlay>
    </b-card>
  </main>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BOverlay,
  BTable,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BOverlay,
    BTable,
    BFormInput,
  },
  watch: {
    // "form.barang_id"(barang_id) {
    //   this.getKonversi(barang_id);
    // },
    // "item.nilai"(value2) {
    //   let hasil = value2 * item.id_satuan;
    //   item.jumlahEdit = hasil;
    // },
    // "item.id_satuan"(value) {
    //   let hasil = value * item.nilai;
    //   item.jumlahEdit = hasil;
    // },
    "form.barang_id"(barang_id) {
      if (barang_id) {
        this.getPenyimpananBarang(barang_id);
        // this.getKonversi(barang_id);
      }
    },
  },
  data: () => ({
    loading: false,
    form: {
      dari: null,
      tujuan: null,
      barang_id: null,
    },
    nilai: 0,
      hasil: 0,
      konversi: 0,
      jumlah: 0,
    barangs: [], // list barang
    availableStocks: [], // tampilkan avaialble stock barang
    mutations: [], // list stock barang yang siap dimutasikan
    mutationFields: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi Awal" },
      { key: "tujuan", label: "Lokasi Tujuan" },
      { key: "stok", label: "Stok Awal" },
      { key: "satuan", label: "Satuan" },
      { key: "jumlahEdit", label: "jumlah" },
      { key: "satuan2", label: "Satuan" },
      { key: "action", label: "#" },
    ],
    otherGudangs: [], // untuk tujuang gudang
    fields: [
      { key: "checkbox", label: "#" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi" },
      { key: "stok", label: "Stok" },
      { key: "satuan", label: "Satuan" },
      // { key: "nilai", label: "Nilai" },
      // { key: "id_satuan", label: "Konversi" },
      { key: "action", label: "Stok Mutasi" },
    ],
  }),
  computed: {
    isIncompletedForm() {
      if (
        this.form.tujuan != null &&
        this.form.barang_id != null &&
        this.mutations.length > 0
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    edit(index) {
      this.availableStocks[index].isEdit = true;
    },
    submit() {
      if (this.isIncompletedForm) {
        this.displayError({
          message: "Harap lengkapi form",
        });
        return false;
      }

      // set payload
      let payload = [];
      this.mutations.map((mutation) => {
        payload.push({
          dari: this.myGudang.id,
          tujuan: this.form.tujuan.value,
          barang_id: mutation.barang ? mutation.barang.id : null,
          jumlah: mutation.jumlahEdit,
          penyimpanan_dari: mutation.id,
        });
      });

      this.loading = true;
      this.$store
        .dispatch("mutasi/save", payload)
        .then(() => {
          this.displaySuccess({
            message: "Mutasi berhasil disimpan!",
          });
          setTimeout(() => {
            this.$router.push("/mutasi");
          }, 1000);
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getLokasi(stock) {
      let lokasi = [];
      if (stock.gudang) {
        lokasi.push(`Gudang: ${stock.gudang.nama_gudang}`);
      }
      if (stock.palet) {
        lokasi.push(`Palet: ${stock.palet.palet}`);
      }
      if (stock.blok) {
        lokasi.push(`Blok: ${stock.blok.blok}`);
      }
      if (stock.rak) {
        lokasi.push(`Rak: ${stock.rak.rak}`);
      }
      if (stock.laci) {
        lokasi.push(`Laci: ${stock.laci.laci}`);
      }

      return lokasi.join(", ");
    },
    addBarang(stock) {
      this.mutations.push(stock);
    },
    getPenyimpananBarang(barang_id) {
      this.loading = true;
      let params = {
        barang_id,
      };
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id;
      }
      this.$store
        .dispatch("penyimpanan/getData", params)
        .then((stocks) => {
          stocks.map((stock) => {
            stock.isEdit = false;
            stock.jumlahEdit = stock.jumlah;
            stock.satuan = stock.satuan;
          });
          this.loading = false;
          this.availableStocks = JSON.parse(JSON.stringify(stocks));
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getBarangs() {
      const barangs = await this.$store.dispatch("barang/getDataV2");
      barangs.map((barang) => {
        this.barangs.push({
          value: barang.id,
          text: barang.nama,
        });
      });
    },
    async getGudangs() {
      const gudangs = await this.$store.dispatch("gudang/getData", { jenis: 1 });
      gudangs.map((gudang) => {
        if (gudang.id != this.myGudang.id) {
          this.otherGudangs.push({
            value: gudang.id,
            text: gudang.nama_gudang,
          });
        }
      });
    },
  },
  created() {
    if (!this.isAdminGudang) {
      this.$router.push("/");
    }
    this.getBarangs();
    this.getGudangs();
  },
};
</script>
